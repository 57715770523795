import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Wams from '../assets/WAMSLogo.png'
import IIITDM from '../assets/IIITDM.jpg'
import IEEE from '../assets/sponsors/aps.jpg'
import { HashLink as Link } from 'react-router-hash-link';
import { maxitab, miniimobile, minitab } from '../responsive';

const Container = styled.div`
  width: 100%;
`;

const Middle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 6%;
  background-color: #214B8C;
  background-image: url('https://i.ibb.co/dpxsmjf/wp7166833-antenna-wallpapers.jpg');
  background-size: cover;
  background-blend-mode: multiply;
  background-position: center;
  opacity: 0.9;
`;

const Logo = styled.div`
    display : flex;
    flex-direction : column;
    align-items : center;
    justify-content : center;
    flex : 1;
`

const WamsTitle = styled.p`
    font-size: 35px;
    line-height : 30px;
    text-align: center;
    font-weight: 550;
    color : white;
    ${miniimobile({
        fontSize : '22px',
    })}
`;

const WamsLogo = styled.img`
    width: 20%;
    margin: 6%;
    ${minitab({
        width : '25%',
    })}
`;

const WamsCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start; 
    flex: 1.5;
    margin-left : 10%;
`;

const Wamsfullform = styled.p`
    font-size: 35px;
    line-height: 30px;
    text-align: left;
    font-weight: 550;
    color: white;
    ${miniimobile({
        fontSize: '22px',
    })}
`;

const Date = styled.p`
    line-height: 0px;
    font-size: 20px;
    text-align: left;
    font-weight: 550;
    color: white;
`;

const Loc = styled.p`
    line-height: 25px;
    font-size: 20px;
    text-align: left;
    font-weight: 550;
    color: white;
    width: 95%;
    ${miniimobile({
        fontSize: '16px',
    })}
`;

const IIITDMLogo = styled.img`
    width: 20%;
    margin: 6%;
    border-radius: 50%;
    ${minitab({
        width : '25%',
    })}
`
  
  const HamLower = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    line-height: 35px;
    color: white;
    font-weight: bold;
    background-color: white;
    padding: 1% 0%;
    border-bottom: 2px solid #214B8C;
    border-top: 2px solid #214B8C;
    width: 100%;
    display: ${props => props.hamState? 'none' : ''};
  `

const Lower = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction : row;
    align-items: center;
    flex-wrap : wrap;
    line-height : 25px;
    color : #2254aa;
    font-weight : bold;
    background-color: white;
    padding: 1% 0%;
    border-bottom : 2px solid #2254aa;
    width: 100%;
    ${maxitab({
        display : 'none',
    })}
`;

const Nav = styled.p`
    margin: 0%;
    font-Size: 15px;
    cursor: pointer;
    color : #2254aa;
    transition: all 0.1s ease-out;
    text-align: center;
    &:hover{
        transform: scale(1.1);
        transition: all 0.1s ease-in;
        box-shadow : 0px 0px 10px 2px black;
        padding : 0px 5px;
        border-radius : 5px;
        color : white;
        background-color : #2254aa; 
    }
`;

const SubNavContainer = styled.div`
    position: absolute;
    width : auto;
    top: 22px;
    left: 0px;
    display: none;
    border-radius : 10px;
    border : 2px solid black;
    background-color: #fff;
    box-shadow: 0px 0px 10px 1px black;
    z-index: 999;
    ${maxitab({
        width : '200px',
        top : '0px',
        marginBottom : '10px',
        left : '0%',
        position : 'relative',
    })}
`;

const SubNavItem = styled.p`
    padding: 10px 20px;
    font-size : 15px;
    margin : 0px;
    color : #2254aa;
    text-align : center;
    cursor: pointer;
    &:hover {
        background-color: #214B8C;
        color : white;
    }
`;

const NavItem = styled.div`
    padding : 0% 1.2%;
    &:hover ${SubNavContainer} {
        display: block;
    }
    position : relative;
`

const Hamburger = styled.div`
    background-color : #2254aa;
    display : none;
    border-top : 2px solid black;
    flex-direction : column;
    ${maxitab({
        display : 'flex',
    })}
`

const HamButton = styled.img`
    cursor : pointer;
    width : 30px;
    padding : 5px;
    margin : 0px 10px;
`

const DownloadLink = styled.a`
    text-decoration : none;
`;



const MarqueeAnimate = keyframes`
    0% {
    transform: translateX(100%);
    }
    100% {
        transform: translateX(-300px);
    }
`

const Marquee = styled.div`
    width: 100%;
    overflow: hidden;
    background-color: #214B8C;
`

const MarqueeText = styled.p`
  font-size: 20px;
  font-weight: bold;
  line-height : 10px;
  color: white;
  animation: ${MarqueeAnimate} 15s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
`;

const UpperBlock = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
`

const BottomBlock = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    ${minitab({
        flexDirection : "column"
    })}
`

const IEEELogo = styled.img`
    width : 35%;
`

const Navbar = () => {

    const [hamState, setHamState] = useState(true);

    const handleHamState = () => {
        setHamState(!hamState);
    }

    const [hamImageSrc, setHamImageSrc] = useState("https://i.ibb.co/9cvH93q/icons8-hamburger-menu-50.png");

    useEffect(() => {
        if (hamState === false) {
            setHamImageSrc("https://i.ibb.co/vxxKKt3/icons8-cross-50.png");
        } else {
            setHamImageSrc("https://i.ibb.co/9cvH93q/icons8-hamburger-menu-50.png");
        }
    }, [hamState]);

    return (
        <Container id='top'>
            <Middle>
                <Logo>
                    <UpperBlock>
                        <WamsTitle>WAMS 2025</WamsTitle>
                    </UpperBlock>
                    <BottomBlock>
                        <WamsLogo src={Wams} />
                        <IEEELogo src={IEEE} />
                        <IIITDMLogo src={IIITDM} />
                    </BottomBlock>
                </Logo>
                <WamsCont>
                    <Wamsfullform>Wireless, Antenna & Microwave Symposium</Wamsfullform>
                    <Date>June 5-8, 2025</Date>
                    <Loc>Indian Institute of Information Technology, Design and Manufacturing Kancheepuram, Chennai</Loc>
                </WamsCont>
            </Middle>
            <Hamburger>
                <HamButton id='hamImage' src={hamImageSrc} onClick={handleHamState} />
                <HamLower hamState = {hamState}>
                <Link style={{textDecoration : "none", zIndex : "2"}} to="/home"> <Nav style={{margin : '0px 10px'}}>Home</Nav> </Link>
                <NavItem>
                    <Nav>About▾</Nav>
                    <SubNavContainer hamState = {hamState}>
                       <Link style={{textDecoration : "none", zIndex : "2"}} to="/home#AboutWams" smooth> <SubNavItem>WAMS 2025</SubNavItem> </Link>
                        <hr style={{margin : '0'}} />
                       <Link style={{textDecoration : "none", zIndex : "2"}} to="/home#AboutIIITDM" smooth> <SubNavItem>IIITDM Kancheepuram</SubNavItem> </Link>
                    </SubNavContainer>
                </NavItem>
                <NavItem>
                    <Nav style={{margin : '0px 10px'}}>WAMS Society▾</Nav>
                    <SubNavContainer>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://wams-society.com/members/" target='_blank'> <SubNavItem>Current Members</SubNavItem> </Link> 
                        <hr style={{margin : '0'}}/>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to  = "https://wams-society.com/wams-membership/" target='_blank'>  <SubNavItem>Membership</SubNavItem> </Link> 
                    </SubNavContainer>
                </NavItem>
                <Link style={{textDecoration : "none", zIndex : "2"}} to="/committee"> <Nav style={{margin : '0px 10px'}}>Committees</Nav> </Link>
                <NavItem>
                    <Nav>Authors▾</Nav>
                    <SubNavContainer>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/submit-a-paper"> <SubNavItem>Call for Paper</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>  
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/Paper-Submission" smooth><SubNavItem>Paper Submission</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>  
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/home#dates" smooth><SubNavItem>Important dates</SubNavItem> </Link>
                    </SubNavContainer>
                </NavItem>
                <NavItem>
                    <Nav>Awards▾</Nav>
                    <SubNavContainer>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/students-awards"> <SubNavItem>Awards</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/work-in-progress"> <SubNavItem>Travel grants</SubNavItem> </Link>
                    </SubNavContainer>
                </NavItem>
                <Link style={{textDecoration : "none", zIndex : "2"}} to="/work-in-progress"> <Nav style={{margin : '0px 10px'}}>Registration</Nav> </Link>
                <NavItem>
                    <Nav>Programme▾</Nav>
                    <SubNavContainer>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/work-in-progress"> <SubNavItem>Speakers</SubNavItem> </Link>
                        <hr style={{margin : '0'}}/>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to="/work-in-progress"> <SubNavItem>WiE</SubNavItem> </Link>
                        <hr style={{margin : '0'}}/>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to="/work-in-progress"> <SubNavItem>YP & Student activities</SubNavItem> </Link>
                    </SubNavContainer>
                </NavItem>
                <NavItem>
                    <Nav style={{margin : '0px 10px'}}>Sponsors▾</Nav>
                    <SubNavContainer>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to="/our-sponsor"> <Nav style={{margin : '0px 10px'}}>Our Sponsors</Nav> </Link>
                        <hr style={{margin : '0'}}/>
                        <DownloadLink href="https://jmp.sh/JMFLHLmp" target='_blank'><SubNavItem >Sponsorship brochure</SubNavItem></DownloadLink>
                        <hr style={{margin : '0'}}/>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to  = "/sponsorship">  <SubNavItem>Sponsorship</SubNavItem> </Link> 
                        <hr style={{margin : '0'}}/>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to  = "/promotions">  <SubNavItem>Promotions</SubNavItem> </Link> 
                    </SubNavContainer>
                </NavItem>
                <NavItem>
                    <Nav>Venue▾</Nav>
                    <SubNavContainer>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/travel"> <SubNavItem>Travel</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/accomodation"> <SubNavItem>Accommodation</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/nearby-attractions"> 
                    <SubNavItem>Nearby attractions</SubNavItem> </Link>
                    </SubNavContainer>
                </NavItem>
                <NavItem>
                <Nav style={{margin : '0px 10px'}}>Previous WAMS▾</Nav>
                <SubNavContainer>
                <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://wams2024.com/" target='_blank'>  <SubNavItem>WAMS 2024</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>
                <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://wams-society.com/wams-2023/" target='_blank'>  <SubNavItem>WAMS 2023</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>
                <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://wams-society.com/wams-2022/" target='_blank'>  <SubNavItem>WAMS 2022</SubNavItem> </Link>
                </SubNavContainer>
                </NavItem>
                <Link style={{textDecoration : "none", zIndex : "2"}} to="/contact"> <Nav style={{margin : '0px 10px'}}>Contact Us</Nav> </Link>
            </HamLower>
            </Hamburger>
            <Lower>
                <Link style={{textDecoration : "none", zIndex : "2"}} to="/home"> <Nav style={{margin : '0px 10px'}}>Home</Nav> </Link>
                <NavItem>
                    <Nav>About▾</Nav>
                    <SubNavContainer>
                       <Link style={{textDecoration : "none", zIndex : "2"}} to="/home#AboutWams" smooth> <SubNavItem>WAMS 2025</SubNavItem> </Link>
                        <hr style={{margin : '0'}} />
                       <Link style={{textDecoration : "none", zIndex : "2"}} to="/home#AboutIIITDM" smooth> <SubNavItem>IIITDM Kancheepuram</SubNavItem> </Link>
                    </SubNavContainer>
                </NavItem>
                <NavItem>
                    <Nav style={{margin : '0px 10px'}}>WAMS Society▾</Nav>
                    <SubNavContainer>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://wams-society.com/members/" target='_blank'> <SubNavItem>Current Members</SubNavItem> </Link> 
                        <hr style={{margin : '0'}}/>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to  = "https://wams-society.com/wams-membership/" target='_blank'>  <SubNavItem>Membership</SubNavItem> </Link> 
                    </SubNavContainer>
                </NavItem>
                <Link style={{textDecoration : "none", zIndex : "2"}} to="/committee"> <Nav style={{margin : '0px 10px'}}>Committees</Nav> </Link>
                <NavItem>
                    <Nav>Authors▾</Nav>
                    <SubNavContainer>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/submit-a-paper"> <SubNavItem>Call for Papers</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>  
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/Paper-Submission" smooth><SubNavItem>Paper Submission</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>  
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/home#dates" smooth><SubNavItem>Important dates</SubNavItem> </Link>
                    </SubNavContainer>
                </NavItem>
                <NavItem>
                    <Nav>Awards▾</Nav>
                    <SubNavContainer>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/students-awards"> <SubNavItem>Awards</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/work-in-progress"> <SubNavItem>Travel grants</SubNavItem> </Link>
                    </SubNavContainer>
                </NavItem>
                <Link style={{textDecoration : "none", zIndex : "2"}} to="/work-in-progress"> <Nav style={{margin : '0px 10px'}}>Registration</Nav> </Link>
                <NavItem>
                    <Nav>Programme▾</Nav>
                    <SubNavContainer>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/work-in-progress"> <SubNavItem>Speakers</SubNavItem> </Link>
                        <hr style={{margin : '0'}}/>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to="/work-in-progress"> <SubNavItem>WiE</SubNavItem> </Link>
                        <hr style={{margin : '0'}}/>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to="/work-in-progress"> <SubNavItem>YP & Student activities</SubNavItem> </Link>
                    </SubNavContainer>
                </NavItem>
                <NavItem>
                    <Nav>Sponsors▾</Nav>
                    <SubNavContainer>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to="/our-sponsor"> <SubNavItem >Our Sponsors</SubNavItem> </Link>
                        <hr style={{margin : '0'}}/>
                        <DownloadLink href="https://jmp.sh/JMFLHLmp" target='_blank'><SubNavItem >Sponsorship brochure</SubNavItem></DownloadLink>
                        <hr style={{margin : '0'}}/>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to  = "/sponsorship">  <SubNavItem>Sponsorship</SubNavItem> </Link> 
                        <hr style={{margin : '0'}}/>
                        <Link style={{textDecoration : "none", zIndex : "2"}} to  = "/promotions">  <SubNavItem>Promotions</SubNavItem> </Link> 
                    </SubNavContainer>
                </NavItem>
                <NavItem>
                    <Nav>Venue▾</Nav>
                    <SubNavContainer>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/travel"> <SubNavItem>Travel</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/accomodation"> <SubNavItem>Accommodation</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>
                    <Link style={{textDecoration : "none", zIndex : "2"}} to="/nearby-attractions"> 
                    <SubNavItem>Nearby attractions</SubNavItem> </Link>
                    </SubNavContainer>
                </NavItem>
                <NavItem>
                <Nav style={{margin : '0px 10px'}}>Previous WAMS▾</Nav>
                <SubNavContainer>
                <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://wams2024.com/" target='_blank'>  <SubNavItem>WAMS 2024</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>
                <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://wams-society.com/wams-2023/" target='_blank'>  <SubNavItem>WAMS 2023</SubNavItem> </Link>
                    <hr style={{margin : '0'}}/>
                <Link style={{textDecoration : "none", zIndex : "2"}} to = "https://wams-society.com/wams-2022/" target='_blank'>  <SubNavItem>WAMS 2022</SubNavItem> </Link>
                </SubNavContainer>
                </NavItem>
                <Link style={{textDecoration : "none", zIndex : "2"}} to="/contact"> <Nav style={{margin : '0px 10px'}}>Contact Us</Nav> </Link>
            </Lower>
            <Marquee>
                <MarqueeText>Authors can submit papers now</MarqueeText>
            </Marquee>
        </Container>
    )
}

export default Navbar;

